
import { Options, Vue } from 'vue-class-component';
import { AuthClient } from '@/services/Services';
import { LoginServices } from '@/services/LoginServices';
import { CommonServices } from '@/services/CommonServices';
import * as OM from '@/model';

@Options({})
export default class FbToken extends Vue {
    
    created(){
        let token = <string>this.$route.query.access_token;
        AuthClient.redirectAccessToken('facebook', token)
        .then(x => {
            CommonServices.setAuthToken(x.token);
            let loginResponse: OM.LoginResponse = {
                token: x.token,
                userIdentifier: x.identifier
            }
            LoginServices.loginCallback(loginResponse, this);
        })
    }
}

